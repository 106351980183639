var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between"
  }, [_c('h1', {
    staticClass: "mt-0"
  }, [_vm._v("Privacy Policy")]), _vm.back ? _c('button', {
    staticClass: "btn btn-primary font-weight-bold",
    on: {
      "click": function click($event) {
        return _vm.$emit('back');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Close")))]) : _vm._e()]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.convertMarkdownToHtml(_vm.content))
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }